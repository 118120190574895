import React from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";

// important, because antd styling not working without it
import "antd/dist/antd.min.css";

// Locale setup
import { useSelector } from "react-redux";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from "antd";
import Locale from "./config/translation/locale";

// import pages
import Topbar from "./components/Topbar";
import Home from "./pages/Home";
import Partners from "./pages/Partners";
import Project from "./pages/Project";
import UseCases from "./pages/UseCases";
import OpenData from "./pages/OpenData";
import Activities from "./pages/Activities";
import Material from "./pages/Material";
import News from "./pages/News";
import Events from "./pages/Events";
import Contact from "./pages/Contact";
import Terms from "./pages/Terms";

import { Layout, Row, Col, Space, Button, Image } from "antd";
import { LinkedinOutlined } from "@ant-design/icons";
import athenaLogo from "./assets/athenaLogo.jpeg";
import intracomLogo from "./assets/icomLogo.png";
import eeLogo_en from "./assets/ee_logo_en.jpg";
import eeLogo_el from "./assets/ee_logo_el.jpg";
import grlogo_en from "./assets/greece2_en.jpg";
import grlogo_el from "./assets/greece2_el.jpg";

import IntlMessages from "./utility/intlMessages";

const { Header, Content, Footer } = Layout;

function App() {
  localStorage.setItem("language", "greek");
  const { language } = useSelector((state) => state.languageReducer);
  const currentLocale = Locale[language.locale];

  return (
    <ConfigProvider locale={currentLocale.antd}>
      <IntlProvider
        locale={currentLocale.locale}
        messages={currentLocale.messages}
      >
        <Layout>
          <Header style={{ position: "fixed", zIndex: 1, width: "100%" }}>
            <Topbar />
          </Header>
          <Content
            style={{
              backgroundColor: "var(--background-color-grey)",
              marginTop: 64,
            }}
          >
            <Routes>
              <Route exact path="/" element={<Home />}></Route>
              <Route exact path="partners" element={<Partners />}></Route>
              <Route exact path="project" element={<Project />}></Route>
              <Route exact path="use-cases" element={<UseCases />}></Route>
              <Route exact path="open-data" element={<OpenData />}></Route>
              <Route exact path="activities" element={<Activities />}></Route>
              <Route exact path="material" element={<Material />}></Route>
              <Route exact path="news" element={<News />}></Route>
              <Route exact path="events" element={<Events />}></Route>
              <Route exact path="contact" element={<Contact />}></Route>
              <Route
                exact
                path="terms-and-data-privacy"
                element={<Terms />}
              ></Route>
            </Routes>
          </Content>

          <Footer className="text-align-center">
            <Row className="just-content-center">
              <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                  <a
                    href="https://www.athenarc.gr"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <Image
                      src={athenaLogo}
                      height={80}
                      width={80}
                      preview={false}
                    />
                  </a>
                  <a
                    href="https://www.intracom-telecom.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <Image
                      src={intracomLogo}
                      height={57}
                      width={250}
                      preview={false}
                    />
                  </a>
              </Col>
              <Col
                // span={8}
                xs={24} sm={12} md={8} lg={6} xl={6}
                style={{ border: "1px solid black" }}
                className="d-flex"
              >
                <div className="m-auto">
                  {language.locale === "en" ? (
                    <a href="https://greece20.gov.gr/" target="_blank" rel="noreferrer">
                      <Image
                        src={grlogo_en}
                        className="scaled-image"
                        style={{maxHeight: "60px", maxWidth: "180px"}}
                        preview={false}
                      />
                      <Image
                        src={eeLogo_en}
                        style={{maxHeight: "60px", maxWidth: "260px"}}
                        className="scaled-image"
                        preview={false}
                      />
                    </a>
                  ) : (
                    <a href="https://greece20.gov.gr/" target="_blank" rel="noreferrer">
                      <Image
                        src={grlogo_el}
                        className="scaled-image"
                        style={{maxHeight: "60px", maxWidth: "180px"}}
                        preview={false}
                      />
                      <Image
                        src={eeLogo_el}
                        style={{maxHeight: "60px", maxWidth: "260px"}}
                        className="scaled-image"
                        preview={false}
                      />
                    </a>
                  )}
                </div>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6} xl={6} className="d-grid">
                <Space className="m-auto">
                  <span>
                    <IntlMessages id={"footer.follow"} />:
                  </span>
                  {/* <Button
                    type="link"
                    href="https://twitter.com/?lang=en"
                    icon={<TwitterOutlined />}
                    target="_blank"
                  /> */}
                  <Button
                    type="link"
                    href="https://www.linkedin.com/company/arcadiaproject/"
                    icon={<LinkedinOutlined />}
                    target="_blank"
                  />
                </Space>
                <span>© Copyright 2022 | IMSI - Athena RC</span>
              </Col>
            </Row>
          </Footer>
        </Layout>
      </IntlProvider>
    </ConfigProvider>
  );
}

export default App;

import React from "react";
import styled from "styled-components";
import { Col, Row, Space, Image, Card, Carousel, Pagination } from "antd";
import IntlMessages from "../utility/intlMessages";

import teamImg20220915 from "../assets/20220915_3.jpg";
import beyo_expo from "../assets/beyond_expo.png";
import teamImg20230203 from "../assets/meeting_3_2_23.jpg";
import time_series_plot from "../assets/plot_time_series.png";
import research_summit_2023 from "../assets/AthenaRC_Research_Summit_2023.jpg";
import teamImg20230719 from "../assets/meeting_19_7_23.jpg";
import deth2023 from "../assets/deth.png";
import dtw2023 from "../assets/dtw23.jpg";
import aws1 from "../assets/aws1.jpg";
import aws2 from "../assets/aws2.jpg";
import aws3 from "../assets/aws3.jpg";
import ieee1 from "../assets/ieee_conf1.jpg";
import ieee2 from "../assets/ieee_conf2.jpg";
import ieee_pres from "../assets/ieee_pres.jpg";
import ieee_pres_1 from "../assets/ieee_pres_1.jpg";
import ieee_pres_slide from "../assets/ieee_pres_slide.png";
import ieee from "../assets/ieee.png";
import meeting_8_3 from "../assets/meeting-8-3.jpeg";
import exp_res_1 from "../assets/exp_res_1.png";
import exp_res_2 from "../assets/exp_res_2.png";
import mwc from "../assets/MWC-24.png";
import tmf from "../assets/TMF_Copenhagen_2024.png";
import scien_data from "../assets/scient_data.jpg";
import ieee_access from "../assets/ieee_access.png";
import tif20241 from "../assets/tif20241.jpg";
import tif20242 from "../assets/tif20242.jpg";
import tif20243 from "../assets/tif20243.jpg";
import hm1 from "../assets/hm1.jpg";
import hm2 from "../assets/hm2.png";
import hm3 from "../assets/hm3.png";
import hm4 from "../assets/hm4.png";
import hm5 from "../assets/hm5.png";

const News = () => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const pageSize = 4; // Number of items per page
  const body = document.querySelector('#root');

  const handlePageChange = (page) => {
    setCurrentPage(page);
    // Scroll to top of the page
    body.scrollIntoView({ behavior: 'smooth' });
  };

  const data = [
    {
      key: 18,
      title: "news.card.title18",
      content: "news.card.content18",
      img: [hm1, hm2, hm3, hm4, hm5],
      imgHeight: "350px",
      imgWidth: "500px"
    },
    {
      key: 17,
      title: "news.card.title17",
      content: "news.card.content17",
      img: [tif20241, tif20242, tif20243],
      imgHeight: "350px",
      imgWidth: "500px"
    },
    {
      key: 16,
      title: "news.card.title16",
      content: "news.card.content16",
      img: ieee_access,
      imgHeight: "350px",
      imgWidth: "500px"
    },
    {
      key: 15,
      title: "news.card.title15",
      content: "news.card.content15",
      img: scien_data,
      imgHeight: "350px",
      imgWidth: "500px"
    },
    {
      key: 14,
      title: "news.card.title14",
      content: "news.card.content14",
      img: tmf,
      imgHeight: "350px",
      imgWidth: "500px"
    },
    {
      key: 13,
      title: "news.card.title13",
      content: "news.card.content13",
      img: mwc,
      imgHeight: "350px",
      imgWidth: "500px"
    },
    {
      key: 12,
      title: "news.card.title12",
      content: "news.card.content12",
      img: [meeting_8_3, exp_res_2, exp_res_1],
      imgHeight: "450px",
      imgWidth: "600px"
    },
    {
      key: 11,
      title: "news.card.title11",
      content: "news.card.content11",
      img: ieee,
      imgHeight: "350px",
      imgWidth: "500px"
    },
    {
      key: 10,
      title: "news.card.title10",
      content: "news.card.content10",
      img: [ieee_pres_slide, ieee_pres, ieee_pres_1],
      imgHeight: "450px",
      imgWidth: "500px"
    },
    {
      key: 9,
      title: "news.card.title9",
      content: "news.card.content9",
      img: [ieee1, ieee2],
      imgHeight: "450px",
      imgWidth: "500px"
    },
    {
      key: 8,
      title: "news.card.title8",
      content: "news.card.content8",
      img: [aws1, aws2, aws3],
      imgHeight: "350px",
      imgWidth: "500px"
    },
    {
      key: 7,
      title: "news.card.title7",
      content: "news.card.content7",
      img: dtw2023,
      imgHeight: "350px",
      imgWidth: "500px"
    },
    {
      key: 6,
      title: "news.card.title6",
      content: "news.card.content6",
      img: deth2023,
      imgHeight: "350px",
      imgWidth: "500px"
    },
    {
      key: 5,
      title: "news.card.title5",
      content: ["news.card.content5.1", "news.card.content5.2", "news.card.content5.3", "news.card.content5.4", "news.card.content5.5"],
      img: teamImg20230719,
      imgHeight: "350px",
      imgWidth: "500px"
    },
    {
      key: 4,
      title: "news.card.title4",
      content: "news.card.content4",
      img: research_summit_2023,
      imgHeight: "450px",
      imgWidth: "500px"
    },
    {
      key: 3,
      title: "news.card.title3",
      content: ["news.card.content3.1", "news.card.content3.2", "news.card.content3.3"],
      img: [teamImg20230203, time_series_plot],
      imgHeight: "300px",
      imgWidth: "400px"
    },
    {
      key: 2,
      title: "news.card.title2",
      content: "news.card.content2",
      img: beyo_expo,
      imgHeight: "300px",
      imgWidth: "400px"
    },
    {
      key: 1,
      title: "news.card.title1",
      content: "news.card.content1",
      img: teamImg20220915,
      imgHeight: "350px",
      imgWidth: "500px"
    }
  ];

  const renderRows = () => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize, data.length);
    return data.slice(startIndex, endIndex).map((item, index) => (
      <Card key={index}>
        <Row>
          {index % 2 === 0 ?
            <>
              <Col xs={24} sm={24} md={11} lg={11} xl={11} className="d-flex">
                {/* Check if more than one images are given */}
                {Array.isArray(item.img) ? 
                  <Carousel className="carousel-image" autoplay={true}>
                    {item.img.map((img, index) => (
                      <div key={index}>
                        <Image src={img} 
                        style={{maxHeight: item.imgHeight, maxWidth: item.imgWidth, objectFit: 'cover'}}
                        />
                      </div>
                    ))}
                  </Carousel> : 
                  <Image src={item.img} 
                  className="scaled-image"
                  style={{maxHeight: item.imgHeight, maxWidth: item.imgWidth}} 
                  />
                }
              </Col>
              <Col xs={24} sm={24} md={1} lg={1} xl={1}></Col>
              <Col xs={24} sm={24} md={11} lg={11} xl={11} className="text-align-center">
                <Row>
                  <h2 className="m-auto">
                    <IntlMessages id={item.title} />
                  </h2>
                </Row>
                <Row className="m-top-5">
                  <span className="text-align-center">
                    {/* Check if more than one content are given */}
                    {Array.isArray(item.content) ? 
                      item.content.map((content, index) => (
                        <IntlMessages key={index} id={content} />
                      )) : 
                      <IntlMessages id={item.content} />
                    }
                  </span>
                </Row>
              </Col>
            </> : 
            <>
              <Col xs={24} sm={24} md={11} lg={11} xl={11} className="text-align-center">
                <Row>
                  <h2 className="m-auto">
                    <IntlMessages id={item.title} />
                  </h2>
                </Row>
                <Row className="m-top-5">
                  <span className="text-align-center">
                    {/* Check if more than one content are given */}
                    {Array.isArray(item.content) ? 
                      item.content.map((content, index) => (
                        <>
                          <IntlMessages key={index} id={content} /> <br />
                        </>
                      )) : 
                      <IntlMessages id={item.content} />
                    }
                  </span>
                </Row>
              </Col>
              <Col xs={24} sm={24} md={1} lg={1} xl={1}></Col>
              <Col xs={24} sm={24} md={11} lg={11} xl={11} className="d-flex">
                {/* Check if more than one images are given */}
                {Array.isArray(item.img) ? 
                  <Carousel className="carousel-image" autoplay={true}>
                    {item.img.map((img, index) => (
                      <div key={index}>
                        <Image src={img} />
                      </div>
                    ))}
                  </Carousel> : 
                  <Image src={item.img} 
                  className="scaled-image"
                  style={{maxHeight: item.imgHeight, maxWidth: item.imgWidth}} />
                }
              </Col>
            </>}
        </Row>
      </Card>
    ));
  };


  return (
    <NewsPageWrapper>
      <Row>
        <Col span={24} className="text-align-center">
          {" "}
          <h1 className="page-title">
            <IntlMessages id={"news.title"} />
          </h1>
        </Col>
      </Row>

      <Row >
        <Space
            direction="vertical"
            size={28}
            wrap={true}
            style={{
              display: "flex",
            }}
          > 
          {renderRows()}
        </Space>

        <Pagination
          current={currentPage}
          onChange={handlePageChange}
          total={data.length}
          pageSize={pageSize}
        />
      </Row>
    </NewsPageWrapper>
  );
};

export default News;

const NewsPageWrapper = styled.div`
  margin: 30px;
  
  @media (max-width: 768px) {
  margin: 15px;
  }

  .ant-carousel{
    width: 500px !important;
    margin: auto;
  }

  .carousel-image {
    height: 350px;
    width: 500px;
    object-fit: cover;
  }

  .carousel-image img{
    max-height: 450px;
    height: 350px;
    max-width: 500px;
    width: 600px;
    object-fit: cover;
  }

  .ant-card {
    border-radius: 20px;
    overflow: hidden;
  }

  .ant-card h2 {
    font-size: 30px;
  }

  .ant-card span {
    font-size: 18px;
  }

  .ant-col .ant-image {
    margin: auto;
  }

  @media (max-width: 768px) {
  .ant-card-body {
    padding: 0px; /* Adjust padding as needed */
  }
}
`;
